.App {
  text-align: center;
  color:#000000;
  /* font-family: "arial"; */
  /* font-family: "Suisse Int'l"!important; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
button:active{
  -webkit-appearance: none !important;
    color:black !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-container{
margin-top:25%;
color: white;
padding:15px;
}

.ck-editor__editable {
  min-height: 200px !important;
}

.ck-editor__editable_inline.ck-focused{
  border: 1px solid #c4c4c4!important;
  box-shadow: none!important;
}
.iDfESG:hover{
  color: white !important;
}

.react-confirm-alert-button-group {
  justify-content: flex-end !important ;
}
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.8) !important;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

@media (max-width:800px) {
  .sidebarStyles{
    display:none;
    }
}

.footerStyles{
border-top:1px solid #707070;
width:100%;
height:84px;
font-size:16px;
/* padding:10px; */
vertical-align:middle;
z-index:1;
}
.FixedFooterStyles{
  font-size:16px;
  border-top:1px solid #707070;
width:calc(100% - 250px);
height:84px;
vertical-align:middle;
position:fixed;
bottom:0;
background-color: white;
z-index:1;
}
.errorStyles{
  color: red;
  font-family: "arial";
  margin-top: 5px;
  font-size:16px;
}
.errorStylesGreen{
  color: #B8FFC8;
  font-family: "arial";
  margin-top: 5px;
  font-size:16px;
}

.nav-tabs .nav-link.active {
  border-color: #fff #fff #C7FDCC !important;
}

.nav-tabs .nav-link{
  border: 3px solid transparent !important;

}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  color:#000000 !important;
}

.nav-link{
  padding: .5rem 0px !important;
  margin-right: 40px !important;
  color:#000000 !important;
}
.nav-tabs {
   border-bottom:none !important;
}

textarea:focus, input:focus{
  outline: none !important;
 
}
.general-page-subheader{
margin: 47px 0px 30px;
font-size: 22px;
font-weight: 500;
line-height: 30px;
}
.sites-form{
  font-size:14px;
  line-height: 19px;
  padding-top: 40px; 
  padding-left:50px;
  padding-bottom:59px;
}
.info-labels-wrapper{
  margin-right:50px;
  margin-bottom: 7px;
  width:300px;
  overflow-wrap: break-word;
}
.info-label{
/* font-size: 12px; */
font-size: 14px;
font-weight:300;
color:#141414;
}
input::placeholder { 
  color:#868686;
  opacity: 1; 
}
input{
  border:1px solid #707070;
  -webkit-appearance: none;
  border-radius: 0;
}
select:focus-visible{
  -webkit-appearance: none !important;
  outline: none !important;
}
select{
  -webkit-appearance: none;
  background: url(./assets/arrow-down.png) no-repeat right;
  background-size: 12px 12px;
  background-position-x:calc( 100% - 16px);
}
.select-dropdown{
  padding: 10px 15px 10px;
  width:235px;
}
.input-col{
  padding: 11px;
  width:205px;
}
@media (min-width:1600px) {
  .input-col{
    min-width:235px;
  }
  .footerStyles{
    height: 84px;
  }
  .login-container{
    margin-top:253px;
    }
  .logo-image{
    width:129px;
    height:23px;
  }
  
  .ck.ck-editor{
    width: 1117px !important;;
  }
  .general-page-subheader{
    margin: 47px 0px 30px;
  
  }
  .sites-form{
    padding-right: 50px; 
  }
  .nav-link{
    margin-right: 70px !important;
  }
  .info-labels-wrapper{
    margin-right:120px;
  }
  .select-dropdown{
    padding: 15px;
   
  }
}
@media (max-width:1380px) {
  .info-labels-wrapper{
    margin-bottom:20px;
    width:100%;
  }
}